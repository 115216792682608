@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500;600;700;800;900&family=Roboto:wght@300;400;500;700;900&display=swap");

:root {
  --heading-one: clamp(1.5rem, 2.8vw + 1rem, 3.75rem);
  --heading-two: clamp(1.375rem, 1.7vw + 1rem, 2.5rem);
  --heading-three: clamp(1.25rem, 0.8vw + 1rem, 1.875rem);
  --heading-four: clamp(1.125rem, 0.5vw + 1rem, 1.5rem);
  --heading-five: clamp(1.0625rem, 0.3vw + 1rem, 1.25rem);
  --heading-six: 1rem;
  --main: rgb(225, 155, 5);
}

.section-heading {
  text-align: center;
  margin-bottom: 50px;
}

@media (max-width: 991px) {
  .section-heading {
    margin-bottom: 40px;
  }
}

@media (max-width: 767px) {
  .section-heading {
    margin-bottom: 30px;
  }
}

.section-heading.style-two {
  text-align: left;
}

.section-heading.style-two .section-heading__title::before {
  left: 0;
  -webkit-transform: translateX(0%);
  transform: translateX(0%);
}

.section-heading.style-two .section-heading__title::after {
  left: 25px;
  -webkit-transform: translateX(0px) rotate(-45deg);
  transform: translateX(0px) rotate(-45deg);
}

.section-heading.style-two .section-heading__desc {
  max-width: 550px;
  margin-right: auto;
  margin-left: 0;
}

.section-heading__title {
  position: relative;
  margin-bottom: 5px;
  padding-bottom: 10px;
}

.section-heading__title::before {
  position: absolute;
  content: "";
  width: 60px;
  height: 2px;
  left: 50%;
  -webkit-transform: translateX(-50%);
  transform: translateX(-50%);
  background-color: rgb(var(--main));
  bottom: 0;
  z-index: -1;
}

.section-heading__title::after {
  position: absolute;
  content: "";
  width: 10px;
  height: 10px;
  left: 50%;
  -webkit-transform: translateX(-50%) rotate(-45deg);
  transform: translateX(-50%) rotate(-45deg);
  background-color: rgb(var(--main));
  bottom: -4px;
  z-index: -1;
}

.section-heading__desc {
  max-width: 700px;
  margin-left: auto;
  margin-right: auto;
  margin-top: 15px;
  font-size: 15px;
  color: rgba(255, 255, 255, 0.8);
}

.slick-slide {
  margin: 0 10px;
}

.slick-arrow {
  position: absolute;
  z-index: 1;
  top: 50%;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
  border: none;
  background-color: transparent;
  color: #fff;
  width: 50px;
  height: 50px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  border-radius: 50%;
  -webkit-transition: 0.4s;
  transition: 0.4s;
  border: 2px solid rgb(var(--main));
  color: rgb(var(--main));
  top: -95px;
}

@media (max-width: 991px) {
  .slick-arrow {
    width: 40px;
    height: 40px;
    font-size: 14px;
  }
}

.slick-arrow:hover {
  background-color: rgb(var(--main));
  border-color: rgb(var(--main));
  color: #fff;
}

.slick-next {
  right: 13px;
}

.slick-prev {
  right: -55px;
}

.slick-dots {
  text-align: center;
  padding-top: 0px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
}

.slick-dots li {
  display: inline-block;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.slick-dots li button {
  border: none;
  background-color: rgba(255, 255, 255, 0.3);
  color: #fff;
  margin: 0 3px;
  width: 10px;
  height: 10px;
  text-indent: -9999px;
  -webkit-clip-path: polygon(
    50% 0%,
    100% 25%,
    100% 75%,
    50% 100%,
    0% 75%,
    0% 25%
  );
  clip-path: polygon(50% 0%, 100% 25%, 100% 75%, 50% 100%, 0% 75%, 0% 25%);
  -webkit-transition: 0.5s linear;
  transition: 0.5s linear;
}

.slick-dots li.slick-active button {
  background-color: rgb(var(--main));
}

.section-bg {
  background-color: rgba(255, 255, 255, 0.04);
}

.section-bg-two {
  background-color: rgba(255, 255, 255, 0.06);
}

.border-bottom-primary {
  border-bottom: 2px solid #007bff;
}

.border-bottom-secondary {
  border-bottom: 2px solid #adb5bd;
}

.border-bottom-success {
  border-bottom: 2px solid #17d605;
}

.border-bottom-danger {
  border-bottom: 2px solid #f75959;
}

.border-bottom-warning {
  border-bottom: 2px solid #ff9e42;
}

.border-bottom-info {
  border-bottom: 2px solid #17a2b8;
}

.border-bottom-violet {
  border-bottom: 2px solid #a14ff9;
}

.bg--base {
  background-color: rgb(var(--main)) !important;
}

.bg--base-two {
  background-color: #0e1621 !important;
}

.bg--primary {
  background-color: #007bff;
}

.bg--secondary {
  background-color: #adb5bd;
}

.bg--success {
  background-color: #17d605;
}

.bg--danger {
  background-color: #f75959;
}

.bg--warning {
  background-color: #ff9e42;
}

.bg--info {
  background-color: #17a2b8;
}

.text--base {
  color: rgb(var(--main));
}

.text--primary {
  color: #007bff;
}

.text--secondary {
  color: #adb5bd;
}

.text--success {
  color: #17d605;
}

.text--danger {
  color: #f75959;
}

.text--warning {
  color: #ff9e42;
}

.text--info {
  color: #17a2b8;
}

.my-120 {
  margin-top: 60px;
  margin-bottom: 60px;
}

@media (min-width: 992px) {
  .my-120 {
    margin-top: 120px;
    margin-bottom: 120px;
  }
}

.mt-120 {
  margin-top: 60px;
}

@media (min-width: 992px) {
  .mt-120 {
    margin-top: 120px;
  }
}

.mb-120 {
  margin-bottom: 60px;
}

@media (min-width: 992px) {
  .mb-120 {
    margin-bottom: 120px;
  }
}

.my-60 {
  margin-top: 30px;
  margin-bottom: 30px;
}

@media (min-width: 992px) {
  .my-60 {
    margin-top: 120px;
    margin-bottom: 60px;
  }
}

.mt-60 {
  margin-top: 30px;
}

@media (min-width: 992px) {
  .mt-60 {
    margin-top: 60px;
  }
}

.mb-60 {
  margin-bottom: 30px;
}

@media (min-width: 992px) {
  .mb-60 {
    margin-bottom: 60px;
  }
}

.my-80 {
  margin-top: 40px;
  margin-bottom: 40px;
}

@media (min-width: 992px) {
  .my-80 {
    margin-top: 80px;
    margin-bottom: 80px;
  }
}

.mt-80 {
  margin-top: 40px;
}

@media (min-width: 992px) {
  .mt-80 {
    margin-top: 80px;
  }
}

.mb-80 {
  margin-bottom: 40px;
}

@media (min-width: 992px) {
  .mb-80 {
    margin-bottom: 80px;
  }
}

.my-40 {
  margin-top: 30px;
  margin-bottom: 30px;
}

@media (min-width: 992px) {
  .my-40 {
    margin-top: 40px;
    margin-bottom: 40px;
  }
}

.mt-40 {
  margin-top: 30px;
}

@media (min-width: 992px) {
  .mt-40 {
    margin-top: 40px;
  }
}

.mb-40 {
  margin-bottom: 30px;
}

@media (min-width: 992px) {
  .mb-40 {
    margin-bottom: 40px;
  }
}

.py-100 {
  padding-top: 50px;
  padding-bottom: 50px;
}

@media (min-width: 576px) {
  .py-100 {
    padding-top: 70px;
    padding-bottom: 70px;
  }
}

@media (min-width: 992px) {
  .py-100 {
    padding-top: 100px;
    padding-bottom: 100px;
  }
}

.pt-100 {
  padding-top: 50px;
}

@media (min-width: 576px) {
  .pt-100 {
    padding-top: 70px;
  }
}

@media (min-width: 992px) {
  .pt-100 {
    padding-top: 100px;
  }
}

.pb-100 {
  padding-bottom: 50px;
}

@media (min-width: 576px) {
  .pb-100 {
    padding-bottom: 70px;
  }
}

@media (min-width: 992px) {
  .pb-100 {
    padding-bottom: 100px;
  }
}

.py-50 {
  padding-top: 25px;
  padding-bottom: 25px;
}

@media (min-width: 576px) {
  .py-50 {
    padding-top: 35px;
    padding-bottom: 35px;
  }
}

@media (min-width: 992px) {
  .py-50 {
    padding-top: 50px;
    padding-bottom: 50px;
  }
}

.pt-50 {
  padding-top: 25px;
}

@media (min-width: 576px) {
  .pt-50 {
    padding-top: 35px;
  }
}

@media (min-width: 992px) {
  .pt-50 {
    padding-top: 50px;
  }
}

.pb-50 {
  padding-bottom: 25px;
}

@media (min-width: 576px) {
  .pb-50 {
    padding-bottom: 35px;
  }
}

@media (min-width: 992px) {
  .pb-50 {
    padding-bottom: 50px;
  }
}

.pt-md-60 {
  padding-top: 60px;
}

@media (min-width: 576px) {
  .pt-md-60 {
    padding-top: 80px;
  }
}

@media (min-width: 992px) {
  .pt-md-60 {
    padding-top: 60px;
  }
}

.pb-md-60 {
  padding-bottom: 60px;
}

@media (min-width: 576px) {
  .pb-md-60 {
    padding-bottom: 80px;
  }
}

@media (min-width: 992px) {
  .pb-md-60 {
    padding-bottom: 60px;
  }
}

.py-80 {
  padding-top: 40px;
  padding-bottom: 40px;
}

@media (min-width: 992px) {
  .py-80 {
    padding-top: 80px;
    padding-bottom: 80px;
  }
}

.pt-80 {
  padding-top: 40px;
}

@media (min-width: 992px) {
  .pt-80 {
    padding-top: 80px;
  }
}

.pb-80 {
  padding-bottom: 40px;
}

@media (min-width: 992px) {
  .pb-80 {
    padding-bottom: 80px;
  }
}

.py-40 {
  padding-top: 20px;
  padding-bottom: 20px;
}

@media (min-width: 992px) {
  .py-40 {
    padding-top: 40px;
    padding-bottom: 40px;
  }
}

.pt-40 {
  padding-top: 20px;
}

@media (min-width: 992px) {
  .pt-40 {
    padding-top: 40px;
  }
}

.pb-40 {
  padding-bottom: 20px;
}

@media (min-width: 992px) {
  .pb-40 {
    padding-bottom: 40px;
  }
}

.py-50 {
  padding-top: 30px;
  padding-bottom: 30px;
}

@media (min-width: 992px) {
  .py-50 {
    padding-top: 50px;
    padding-bottom: 50px;
  }
}

.pt-50 {
  padding-top: 30px;
}

@media (min-width: 992px) {
  .pt-50 {
    padding-top: 50px;
  }
}

.pb-50 {
  padding-bottom: 30px;
}

@media (min-width: 992px) {
  .pb-50 {
    padding-bottom: 50px;
  }
}

* {
  margin: 0;
  padding: 0;
  list-style: none;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}

body {
  font-family: "Poppins", sans-serif;
  color: #b9babb;
  word-break: break-word;
  background-color: #0e1621;
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

p {
  font-family: "Poppins", sans-serif;
  font-size: 16px;
  margin: 0;
  font-weight: 400;
  word-break: break-word;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 0 0 20px 0;
  font-family: "Roboto", sans-serif;
  color: #fff;
  line-height: 1.3;
  word-break: break-word;
}

@media (max-width: 767px) {
  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    margin: 0 0 15px 0;
  }
}

h1 {
  font-size: var(--heading-one);
  font-weight: 700;
}

h2 {
  font-size: var(--heading-two);
  font-weight: 600;
}

h3 {
  font-size: var(--heading-three);
  font-weight: 500;
}

h4 {
  font-size: var(--heading-four);
  font-weight: 500;
}

h5 {
  font-size: var(--heading-five);
  font-weight: 500;
}

h6 {
  font-size: var(--heading-six);
  font-weight: 500;
}

h1 > a,
h2 > a,
h3 > a,
h4 > a,
h5 > a,
h6 > a {
  font-weight: 500;
  -webkit-transition: 0.4s;
  transition: 0.4s;
  line-height: 1.4;
  word-break: break-word;
}

a {
  display: inline-block;
  -webkit-transition: 0.3s;
  transition: 0.3s;
  text-decoration: none;
  color: #b9babb;
}

a:hover {
  color: rgb(var(--main));
}

ul {
  margin: 0;
  padding: 0;
  list-style: none;
}

img {
  max-width: 100%;
  height: auto;
  vertical-align: middle;
}

select {
  cursor: pointer;
}

ul,
ol {
  padding: 0;
  margin: 0;
  list-style: none;
}

*:focus {
  outline: none;
}

button {
  cursor: pointer;
  border: none;
  background-color: transparent;
  color: #fff;
  transition: 0.2s linear;
}

button:focus {
  outline: none;
}

span {
  display: inline-block;
}

.faq {
  position: relative;
  z-index: 1;
  overflow: hidden;
}

.faq::before,
.faq::after {
  position: absolute;
  content: "";
  width: 250px;
  height: 250px;
  background-color: rgba(255, 255, 255, 0.1);
  right: -125px;
  top: 0%;
  aspect-ratio: 1;
  z-index: -1;
  -webkit-animation: faq-shape 20s linear infinite;
  animation: faq-shape 20s linear infinite;
  -webkit-clip-path: polygon(
    50% 0%,
    80% 10%,
    100% 35%,
    100% 70%,
    80% 90%,
    50% 100%,
    20% 90%,
    0% 70%,
    0% 35%,
    20% 10%
  );
  clip-path: polygon(
    50% 0%,
    80% 10%,
    100% 35%,
    100% 70%,
    80% 90%,
    50% 100%,
    20% 90%,
    0% 70%,
    0% 35%,
    20% 10%
  );
}

@media (max-width: 991px) {
  .faq::before,
  .faq::after {
    right: -150px;
    width: 300px;
    height: 300px;
  }
}

@media (max-width: 767px) {
  .faq::before,
  .faq::after {
    right: -100px;
    width: 200px;
    height: 200px;
  }
}

@media (max-width: 575px) {
  .faq::before,
  .faq::after {
    right: -75px;
    width: 150px;
    height: 150px;
  }
}

.faq::after {
  background-color: rgba(var(--main), 0.3);
  left: -125px;
  -webkit-clip-path: unset;
  clip-path: unset;
  border-radius: 50%;
  -webkit-animation: faq-circle 20s linear infinite;
  animation: faq-circle 20s linear infinite;
}

@-webkit-keyframes faq-shape {
  0% {
    -webkit-transform: translateY(0px);
    transform: translateY(0px);
  }

  50% {
    -webkit-transform: translateY(200px);
    transform: translateY(200px);
  }

  100% {
    -webkit-transform: translateY(0px);
    transform: translateY(0px);
  }
}

@keyframes faq-shape {
  0% {
    -webkit-transform: translateY(0px);
    transform: translateY(0px);
  }

  50% {
    -webkit-transform: translateY(200px);
    transform: translateY(200px);
  }

  100% {
    -webkit-transform: translateY(0px);
    transform: translateY(0px);
  }
}

@-webkit-keyframes faq-circle {
  0% {
    -webkit-transform: translateY(200px);
    transform: translateY(200px);
  }

  50% {
    -webkit-transform: translateY(0px);
    transform: translateY(0px);
  }

  100% {
    -webkit-transform: translateY(200px);
    transform: translateY(200px);
  }
}

@keyframes faq-circle {
  0% {
    -webkit-transform: translateY(200px);
    transform: translateY(200px);
  }

  50% {
    -webkit-transform: translateY(0px);
    transform: translateY(0px);
  }

  100% {
    -webkit-transform: translateY(200px);
    transform: translateY(200px);
  }
}

.custom--accordion .accordion-item {
  background-color: #0e1621 !important;
}

.custom--accordion .accordion-item:not(:last-child) {
  margin-bottom: 20px;
}

.custom--accordion .accordion-body {
  border-left: 3px solid rgb(var(--main));
  background-color: rgba(255, 255, 255, 0.07);
}

.custom--accordion .accordion-body__desc {
  color: rgba(255, 255, 255, 0.8);
}

.custom--accordion .accordion-button {
  background-color: transparent;
  color: #fff;
  font-size: 20px;
  border: 1px solid rgba(255, 255, 255, 0.15);
  border-radius: 0px;
  padding: 13px 10px;
}

@media (max-width: 575px) {
  .custom--accordion .accordion-button {
    font-size: 18px;
    padding: 13px;
    padding-right: 30px;
  }
}

.custom--accordion .accordion-button::after {
  background-image: none;
}

.custom--accordion .accordion-button:focus {
  -webkit-box-shadow: none;
  box-shadow: none;
}

.custom--accordion .accordion-button:not(.collapsed) {
  color: rgb(var(--main)) !important;
  background-color: transparent !important;
  -webkit-box-shadow: none;
  box-shadow: none;
}

.custom--accordion .accordion-button:not(.collapsed)::after {
  -webkit-transform: rotate(0deg);
  transform: rotate(0deg);
  background-image: none;
}

.custom--accordion .accordion-button[aria-expanded="true"]::after,
.custom--accordion .accordion-button[aria-expanded="false"]::after {
  font-family: "Font Awesome 5 Free";
  font-weight: 900;
  content: "\f077";
  display: inline-block;
  position: relative;
  margin-left: auto;
  width: 0 !important;
  right: 15px;
  font-size: 15px;
}

@media (max-width: 575px) {
  .custom--accordion .accordion-button[aria-expanded="true"]::after,
  .custom--accordion .accordion-button[aria-expanded="false"]::after {
    right: -13px;
    font-size: 14px;
  }
}

.custom--accordion .accordion-button[aria-expanded="false"]::after {
  content: "\f078";
}

button {
  border: none;
}

button:focus {
  outline: none;
  -webkit-box-shadow: none;
  box-shadow: none;
}

.btn:focus {
  outline: none;
  -webkit-box-shadow: none;
  box-shadow: none;
}

.btn--base {
  color: #fff;
  font-weight: 500;
  background-color: rgb(var(--main));
  padding: 11px 30px;
  border-radius: 4px;
  position: relative;
  z-index: 1;
  border: none;
  text-align: center;
  overflow: hidden;
  border: 2px solid transparent;
}

@media (max-width: 767px) {
  .btn--base {
    padding: 11px 25px;
  }
}

@media (max-width: 575px) {
  .btn--base {
    padding: 11px 20px;
  }
}

.btn--base:disabled {
  background-color: rgba(var(--main), 0.6);
}

.btn--base:disabled:hover {
  border-color: transparent;
  background-color: rgba(var(--main), 0.6);
  cursor: not-allowed;
}

.btn--base.outline {
  border: 2px solid rgb(var(--main));
  color: #fff;
  background-color: transparent;
}

.btn--base.outline:hover {
  background-color: rgb(var(--main));
}

.btn--base.btn--sm {
  padding: 5px 15px;
  font-size: 15px;
}

.btn--xsm {
  padding: 3px 8px;
  font-size: 15px;
}

.btn--base.bg--white {
  background-color: #fff;
  color: rgb(var(--main));
}

.btn--base:hover {
  color: #fff;
  background-color: transparent;
  border-color: rgb(var(--main));
}

.btn--base:hover::before {
  height: 100%;
}

.btn--simple {
  color: rgb(var(--main));
}

.btn--simple__icon {
  font-size: 14px;
}

.btn--primary {
  background-color: #007bff;
  color: #fff;
}

.btn--primary:hover {
  color: #fff;
  background-color: #007bffe1;
}

.btn--secondary {
  background-color: #adb5bd;
  color: #fff;
}

.btn--secondary:hover {
  color: #fff;
  background-color: #636c75;
}

.btn--success {
  background-color: #17d605;
  color: #fff;
}

.btn--success:hover {
  color: #fff;
  background-color: #13af59;
}

.btn--danger {
  background-color: #f75959;
  color: #fff;
}

.btn--danger:hover {
  color: #fff;
  background-color: #ad2b2b;
}

.btn--warning {
  background-color: #ff9e42;
  color: #fff;
}

.btn--warning:hover {
  color: #fff;
  background-color: #d47c29;
}

.btn--info {
  background-color: #17a2b8;
  color: #fff;
}

.btn--info:hover {
  background-color: #17a2b8e8;
  color: #fff;
}

.custom--card {
  border-radius: 5px;
  background-color: rgba(255, 255, 255, 0.05) !important;
  border: 1px solid rgba(255, 255, 255, 0.05);
}

.custom--card .card-header {
  border-bottom: 1px solid rgba(255, 255, 255, 0.1);
  padding: 15px 20px;
}

.custom--card .card-title {
  margin-bottom: 0;
}

.custom--card .card-body {
  padding: 20px;
  border-radius: 5px;
}

.custom--card .card-body__icon {
  font-size: 26px;
  color: #fff;
}

label {
  margin-bottom: 10px;
  font-size: 15px;
  color: rgba(255, 255, 255, 0.8);
  font-weight: 400;
}

.form--control {
  height: 50px;
  border-radius: 3px;
  color: #0e1621;
  font-weight: 400;
  outline: none;
  width: 100%;
  padding: 11px 15px;
  background-color: rgb(255 255 255 / 3%);
  border: 1px solid rgba(255, 255, 255, 0.03);
  color: #fff;
}

@media (max-width: 767px) {
  .form--control {
    padding: 10px 25px;
  }
}

@media (max-width: 575px) {
  .form--control {
    padding: 10px 20px;
  }
}

.form--control::-webkit-input-placeholder {
  color: rgba(255, 255, 255, 0.4);
  font-size: 14px;
}

.form--control:-ms-input-placeholder {
  color: rgba(255, 255, 255, 0.4);
  font-size: 14px;
}

.form--control::-ms-input-placeholder {
  color: rgba(255, 255, 255, 0.4);
  font-size: 14px;
}

.form--control::placeholder {
  color: rgba(255, 255, 255, 0.4);
  font-size: 14px;
}

.form--control:focus {
  border: 1px solid rgb(var(--main));
  border-radius: 3px;
  background-color: rgb(255 255 255 / 3%);
  color: #fff !important;
}

.form--control:focus {
  -webkit-box-shadow: none;
  box-shadow: none;
  border-color: rgb(var(--main));
}

.form--control:disabled,
.form--control[readonly] {
  background-color: #03050830;
  color: rgba(255, 255, 255, 0.472);
  opacity: 1;
  border: 0;
}

.form--control[type="file"] {
  line-height: 48px;
  padding: 0;
  border-radius: 4px;
}

.form--control[type="file"]::-webkit-file-upload-button {
  background: #ffffff14 !important;
  padding: 5px 20px;
  color: #fff;
}

textarea.form--control {
  height: 150px;
  padding: 15px;
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  -webkit-transition: background-color 5000s ease-in-out 0s;
  transition: background-color 5000s ease-in-out 0s;
  -webkit-text-fill-color: #fff !important;
}

input:-webkit-autofill,
textarea:-webkit-autofill,
select:-webkit-autofill {
  -webkit-text-fill-color: #fff !important;
}

.input--group {
  position: relative;
}

.input--icon {
  position: absolute;
  left: 0;
  top: 50%;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
  color: rgb(var(--main));
}

.password-show-hide {
  position: absolute;
  right: 20px;
  z-index: 3;
  cursor: pointer;
  top: 50%;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
}

input#send-amount::-webkit-outer-spin-button,
input#send-amount::-webkit-inner-spin-button {
  -webkit-appearance: none;
}

input#send-amount[type="number"] {
  -moz-appearance: textfield;
}

.custom--select {
  position: relative;
  background-color: rgb(255 255 255 / 3%);
}

.custom--select.input-group-text {
  height: auto;
  border: 0 !important;
}

.custom--select.style-two .icon {
  position: absolute;
  right: 6px;
  top: 50%;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
  font-size: 15px;
  background-color: #b9babb;
  height: 80%;
  width: 25%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -ms-flex-align: center;
  -webkit-box-align: center;
  align-items: center;
  color: #fff;
}

.custom--select.style-two .icon i {
  margin-left: 10px;
  margin-top: -10px;
}

.custom--select.style-two .form--select {
  border: 1px solid #c9c3c36b;
}

.custom--select.style-two .form--select:focus {
  border-radius: 3px;
  -webkit-box-shadow: 0px 1px 4px 1px #dddddd4a;
  box-shadow: 0px 1px 4px 1px #dddddd4a;
}

.custom--select .icon {
  position: absolute;
  right: 15px;
  top: 45%;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
  font-size: 15px;
  z-index: 3;
}

.form--select {
  height: 50px;
  position: relative;
  padding-left: 10px;
  background-color: rgba(14, 22, 33, 0.4);
  border: 1px solid #c9c3c36b;
  color: #b9babb;
  background-image: none;
}

.form--select.style-two {
  padding-left: 60px;
}

.form--select:focus {
  background-color: rgba(14, 22, 33, 0.6);
  -webkit-box-shadow: none;
  box-shadow: none;
}

.input-group--text {
  background: rgb(var(--main));
  color: #fff;
  border-radius: 0;
  margin-right: 5px;
  padding: 0;
  position: absolute;
  right: 0;
  border: transparent !important;
  z-index: 5;
  height: 80%;
  top: 50%;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
}

.custom--checkbox__text {
  position: relative;
  padding-left: 25px;
  font-size: 16px;
}

.custom--checkbox__text-link {
  color: rgb(var(--main));
}

.custom--checkbox__text::before {
  position: absolute;
  content: "";
  width: 15px;
  height: 15px;
  border: 1px solid rgb(var(--main));
  left: 0;
  border-radius: 2px;
  top: 3px;
}

::-webkit-calendar-picker-indicator {
  -webkit-filter: invert(0.5);
  filter: invert(0.5);
}

::-webkit-calendar-picker-indicator {
  background-image: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" width="16" height="15" viewBox="0 0 24 24"><path fill="%23bbbbbb" d="M20 3h-1V1h-2v2H7V1H5v2H4c-1.1 0-2 .9-2 2v16c0 1.1.9 2 2 2h16c1.1 0 2-.9 2-2V5c0-1.1-.9-2-2-2zm0 18H4V8h16v13z"/></svg>');
}

.nice--select.size-sm {
  height: 35px;
  line-height: 35px;
}

.nice--select.size-sm .current {
  color: #fff;
}

.nice--select.size-sm .option {
  line-height: 30px;
  min-height: 30px;
  padding: 0 8px;
  color: #fff !important;
}

.nice--select {
  color: #fff;
  background-color: rgba(255, 255, 255, 0.1) !important;
  border: 1px solid rgba(255, 255, 255, 0.1);
  height: 50px;
  line-height: 50px;
}

.nice--select .current {
  font-size: 14px;
  font-family: "Poppins", sans-serif;
  color: rgba(255, 255, 255, 0.6);
}

.nice--select .list {
  -webkit-box-shadow: 0px 1px 4px 1px #dddddd4a;
  box-shadow: 0px 1px 4px 1px #dddddd4a;
  background-color: #0e1621;
  max-height: 200px;
  overflow-y: auto;
}

.nice--select .list::-webkit-scrollbar {
  width: 3px;
  height: 3px;
}

.nice--select:hover {
  border: 1px solid rgba(255, 255, 255, 0.04);
}

.nice--select:focus {
  border: 1px solid rgb(var(--main));
}

.nice--select .option {
  color: #b9babb;
  font-size: 14px;
  font-family: "Poppins", sans-serif;
}

.nice--select .option.selected.focus {
  font-weight: 600;
  background-color: rgb(var(--main)) !important;
  color: #fff;
}

.nice--select .option.selected.focus:hover {
  background-color: rgb(var(--main)) !important;
  color: #fff !important;
}

.nice--select .option:hover {
  background-color: rgb(var(--main));
  color: #fff;
}

.nice--select .wide {
  border: 1px solid #c9c3c36b;
  gap: 0px;
}

.custom--modal .modal-content {
  text-align: center;
  background-color: #111b28;
  border-radius: 10px !important;
}

.custom--modal .modal-body {
  padding: 0 25px 25px;
}

.custom--modal .modal-header {
  border-bottom: 1px solid #ffffff19;
  margin-bottom: 25px;
  padding: 25px 25px 15px 25px;
}

.custom--modal .modal-header .close {
  width: 35px;
  height: 35px;
  background-color: #f75959;
  font-size: 25px;
  line-height: 1;
  border-radius: 4px;
  transition: 0.2s linear;
}

.custom--modal .modal-header .close:hover {
  background-color: #f33737;
}

.custom--modal .modal-header .close:focus {
  -webkit-box-shadow: none;
  box-shadow: none;
}

.custom--modal .modal-icon i {
  font-size: 32px;
  color: rgb(var(--main));
  border: 3px solid rgb(var(--main));
  width: 50px;
  height: 50px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  border-radius: 50%;
}

.custom--modal .modal-footer {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -ms-flex-negative: 0;
  flex-shrink: 0;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  padding: 10px 25px 15px;
  border-top: 1px solid #ffffff19;
  justify-content: flex-end;
}

.custom--modal .list-group {
  margin: -9px 0 !important;
}

.custom--modal .list-group-item {
  border: 0;
  border-bottom: 1px solid #ffffff19;
  padding: 9px 0;
}

.custom--modal .list-group-item:last-child {
  border-bottom: 0;
}

.pagination {
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  margin-top: 50px;
}

.pagination .page-item .page-link {
  border: 0;
  margin: 0 5px;
  border-radius: 50%;
  height: 40px;
  width: 40px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  background-color: rgba(255, 255, 255, 0.1);
  font-weight: 500;
  color: #fff;
}

.pagination .page-item.active .page-link,
.pagination .page-item .page-link:hover {
  background-color: rgb(var(--main));
  color: #fff;
}

.pagination .page-item .page-link:focus {
  -webkit-box-shadow: none;
  box-shadow: none;
}

.table {
  margin: 0;
  font-size: 15px;
  border-radius: 5px;
  border-spacing: 0;
  border-collapse: separate;
}

.table thead tr th {
  background-color: rgba(255, 255, 255, 0.15);
  text-align: center;
  font-size: 15px;
  padding: 15px;
  color: #fff;
  font-family: "Roboto", sans-serif;
  font-weight: 500;
  border-bottom: 0;
}

.table thead tr th:first-child {
  text-align: left;
  border-radius: 5px 0 0 0;
}

.table thead tr th:last-child {
  border-radius: 0 5px 0 0;
  text-align: right;
}

.table tbody {
  background-color: #0e1621;
  border-top: 0;
}

.table tbody tr:nth-child(even) {
  background-color: #080c13;
}

.table tbody tr:last-child {
  border-bottom: 0;
}

.table tbody tr td {
  text-align: center;
  vertical-align: middle;
  padding: 15px;
  border-width: 1px;
  border: 0;
  font-family: "Roboto", sans-serif;
  color: #fff;
}

.table tbody tr td::before {
  content: attr(data-label);
  font-family: "Roboto", sans-serif;
  font-size: 15px;
  color: #fff;
  font-weight: 500;
  display: none;
  width: 35% !important;
  text-align: left;
}

.table tbody tr td:first-child {
  text-align: left;
  border-left: 1px solid #ffffff30;
}

.table tbody tr:last-child td:first-child {
  border-radius: 0 0 0 10px;
}

.table tbody tr:last-child td:last-child {
  border-radius: 0 0 10px 0;
}

.table tbody tr:last-child td {
  border-bottom: 1px solid #ffffff30;
}

.table tbody tr td:last-child {
  text-align: right;
  border-right: 1px solid #ffffff30;
}

@media (max-width: 767px) {
  .table--responsive--md thead {
    display: none;
  }

  .table--responsive--md tbody tr {
    display: block;
  }

  .table--responsive--md tbody tr td {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
    gap: 35px;
    text-align: right;
    padding: 10px;
    border: none;
    border-bottom: 1px solid #c9c3c36b;
  }

  .table tbody tr td:first-child {
    text-align: right;
    border: 0;
  }

  .table--responsive--md tbody tr td:last-child {
    border: none;
  }

  .table--responsive--md tbody tr td::before {
    display: block;
  }
}

@media (max-width: 767px) {
  .table--responsive--md tbody tr td {
    border: 0 !important;
  }
}

@media (max-width: 991px) {
  .table--responsive--lg thead {
    display: none;
  }

  .table--responsive--lg tbody tr {
    display: block;
  }

  .table--responsive--lg tbody tr td {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
    gap: 35px;
    text-align: right;
    padding: 10px;
    border: none;
    border-bottom: 1px solid #c9c3c36b;
  }

  .table tbody tr td:first-child {
    text-align: right;
    border: 0;
  }

  .table--responsive--lg tbody tr td:last-child {
    border: 0 !important;
  }

  .table--responsive--lg tbody tr td::before {
    display: block;
  }
}

@media (max-width: 991px) {
  .table--responsive--lg tbody tr td {
    border: 0 !important;
  }
}

@media (max-width: 1199px) {
  .table--responsive--xl thead {
    display: none;
  }

  .table--responsive--xl tbody tr {
    display: block;
  }

  .table--responsive--xl tbody tr td {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
    gap: 35px;
    text-align: right;
    padding: 10px;
    border: none;
    border-bottom: 1px solid #c9c3c36b;
  }

  .table tbody tr td:first-child {
    text-align: right;
    border: 0;
  }

  .table--responsive--xl tbody tr td:last-child {
    border: none;
  }

  .table--responsive--xl tbody tr td::before {
    display: block;
  }
}

@media (max-width: 991px) {
  .table--responsive--xl tbody tr td {
    border: 0 !important;
  }
}

.custom--tab {
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  border-radius: 6px;
  margin-bottom: 40px !important;
  margin: -5px;
}

.custom--tab .nav-item {
  border-bottom: 0;
  padding: 5px;
}

.custom--tab .nav-item .nav-link {
  color: #fff;
  padding: 8px 25px;
  background-color: transparent !important;
  border-radius: 5px;
  -webkit-transition: 0.4s;
  transition: 0.4s;
  border: 1px solid rgba(255, 255, 255, 0.2) !important;
}

@media (max-width: 1199px) {
  .custom--tab .nav-item .nav-link {
    padding: 12px 15px;
  }
}

@media (max-width: 575px) {
  .custom--tab .nav-item .nav-link {
    padding: 7px 12px;
  }
}

.custom--tab .nav-item .nav-link.active {
  color: #fff;
  background-color: rgb(var(--main)) !important;
  border: 1px solid transparent !important;
}

.custom--tab .nav-item .nav-link.active:hover {
  color: #fff;
}

.custom--tab .nav-item .nav-link:hover {
  color: rgb(var(--main));
}

.badge {
  border-radius: 3px;
  padding: 4px 12px;
  font-size: 10px;
  font-weight: 500;
  border-radius: 30px;
}

.badge--base {
  background-color: transparent;
  border: 1px solid rgb(var(--main));
  color: #fff;
}

.badge--primary {
  background-color: transparent;
  border: 1px solid #007bff;
  color: #fff;
}

.badge--secondary,
.badge--dark {
  background-color: transparent;
  border: 1px solid #adb5bd;
  color: #fff;
}

.badge--success {
  background-color: transparent;
  border: 1px solid #17d605;
  color: #fff;
}

.badge--danger {
  background-color: transparent;
  border: 1px solid #f75959;
  color: #fff;
}

.badge--warning {
  background-color: transparent;
  border: 1px solid #ff9e42;
  color: #fff;
}

.badge--info {
  background-color: transparent;
  border: 1px solid #17a2b8;
  color: #fff;
}

.custom--select {
  height: 50px;
  line-height: 40px;
  background-color: rgb(var(--main));
  color: #fff;
  background-color: rgb(255 255 255 / 3%);
  border: 1px solid rgba(255, 255, 255, 0.1) !important;
  border: 0;
  border-radius: 3px;
  -moz-appearance: auto;
  appearance: auto;
  -webkit-appearance: auto;
  padding: 3px 10px;
}

.custom--select:focus {
  border-color: rgb(var(--main)) !important;
}

.custom--select option {
  background-color: #0e1621;
}

.custom--select::-webkit-scrollbar {
  width: 3px;
  height: 3px;
}

.navbar-brand {
  padding-top: 0;
  padding-bottom: 0;
}

@media (max-width: 991px) {
  .navbar-brand.logo {
    -webkit-box-ordinal-group: 0;
    -ms-flex-order: -1;
    order: -1;
  }
}

.navbar-brand.logo img {
  height: 100%;
  max-width: 100%;
  max-height: 40px;
}

@media (max-width: 991px) {
  .navbar-brand.logo img {
    max-width: 150px;
  }
}

@media (max-width: 575px) {
  .navbar-brand.logo img {
    max-width: 120px;
    max-height: 54px;
  }
}

.header-bottom {
  position: relative;
  z-index: 5;
  background-color: rgba(14, 22, 33, 0.8);
  border-bottom: 1px solid rgba(255, 255, 255, 0.1);
}

@media (max-width: 991px) {
  .header-bottom {
    top: 0px;
    background-color: #0e1621;
  }
}

.header-bottom.fixed-header {
  background-color: #0e1621;
  position: -webkit-sticky;
  position: sticky;
  -webkit-transition: 0.5s;
  transition: 0.5s;
  border-bottom: 1px solid rgba(255, 255, 255, 0.1);
  top: 0px;
  -webkit-animation: slide-down 0.8s;
  animation: slide-down 0.8s;
  width: 100%;
}

@media (max-width: 991px) {
  .header-bottom {
    padding: 10px 0;
  }
}

@-webkit-keyframes slide-down {
  0% {
    opacity: 0;
    -webkit-transform: translateY(-150%);
    transform: translateY(-150%);
  }

  100% {
    opacity: 1;
    -webkit-transform: translateY(0);
    transform: translateY(0);
  }
}

@keyframes slide-down {
  0% {
    opacity: 0;
    -webkit-transform: translateY(-150%);
    transform: translateY(-150%);
  }

  100% {
    opacity: 1;
    -webkit-transform: translateY(0);
    transform: translateY(0);
  }
}

.navbar {
  padding: 0 !important;
}

.nav-menu {
  padding-top: 0;
  padding-bottom: 0;
}

.nav-menu .nav-item {
  position: relative;
  margin: 0 10px;
}

@media (max-width: 991px) {
  .nav-menu .nav-item {
    text-align: left;
    display: block;
    position: relative;
    margin: 0;
  }
}

.nav-menu .nav-item:hover .nav-link {
  color: rgb(var(--main)) !important;
}

.nav-menu .nav-item:hover .nav-link::before {
  width: 100%;
}

.nav-menu .nav-item:hover .nav-link .nav-item__icon {
  -webkit-transform: rotate(180deg);
  transform: rotate(180deg);
  -webkit-transition: 0.2s;
  transition: 0.2s;
}

.nav-menu .nav-item .nav-link {
  font-weight: 500;
  font-size: 16px;
  color: #fff !important;
  padding: 33px 0 !important;
  position: relative;
  cursor: pointer;
}

.nav-menu .nav-item .nav-link.active {
  color: rgb(var(--main)) !important;
}

.nav-menu .nav-item .nav-link.active::before {
  width: 100%;
}

@media (max-width: 991px) {
  .nav-menu .nav-item .nav-link {
    display: inline-block;
    margin-bottom: 8px;
    padding: 0 !important;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
  }
}

.nav-menu .nav-item .nav-link:hover::before,
.nav-menu .nav-item .nav-link.active::before {
  left: 0;
  -webkit-transition: 0.3s;
  transition: 0.3s;
}

.nav-menu .nav-item .nav-link::before {
  position: absolute;
  content: "";
  right: 0;
  bottom: 30px;
  width: 0;
  height: 2px;
  background-color: rgb(var(--main));
  -webkit-transition: 0.3s;
  transition: 0.3s;
}

.nav-menu .nav-item .nav-link .nav-item__icon {
  -webkit-transition: 0.3s;
  transition: 0.3s;
  font-size: 13px;
  margin-left: 2px;
}

@media (max-width: 991px) {
  .nav-menu .nav-item .nav-link .nav-item__icon {
    margin-right: 6px;
  }
}

@media (min-width: 992px) {
  .dropdown-menu {
    display: block;
    visibility: hidden;
    opacity: 0;
    -webkit-transition: 0.3s;
    transition: 0.3s;
    top: 100%;
    left: 0;
    padding: 0 !important;
    -webkit-transform: scaleY(0);
    transform: scaleY(0);
    -webkit-transform-origin: top center;
    transform-origin: top center;
    -webkit-transition: 0.2s linear;
    transition: 0.2s linear;
    overflow: hidden;
  }

  .dropdown-menu__list {
    border-bottom: 1px solid #e6e6e6;
  }

  .dropdown-menu__list:last-child {
    border-bottom: 3px solid rgb(var(--main));
  }

  .dropdown-menu__link {
    padding: 7px 20px;
    font-weight: 500;
    font-size: 16px;
    -webkit-transition: 0.3s;
    transition: 0.3s;
  }

  .nav-menu .nav-item:hover .dropdown-menu {
    visibility: visible;
    opacity: 1;
    top: 100% !important;
    -webkit-transform: scaleY(1);
    transform: scaleY(1);
  }
}

@media (max-width: 991px) {
  .nav-menu {
    margin-top: 20px;
  }

  .nav-menu .nav-item:hover .nav-link .nav-item__icon {
    -webkit-transform: rotate(0deg) !important;
    transform: rotate(0deg) !important;
  }

  .nav-item {
    border-bottom: 1px solid #c9c3c340;
    padding: 10px 0;
  }

  .nav-item:last-child {
    border-bottom: none;
  }

  .nav-item .nav-link {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    margin: 0 !important;
  }

  .nav-item .nav-link::before {
    display: none;
  }

  .nav-item .nav-link.show[aria-expanded="true"] {
    color: rgb(var(--main)) !important;
  }

  .nav-item .nav-link.show[aria-expanded="true"] i {
    -webkit-transform: rotate(180deg);
    transform: rotate(180deg);
  }

  .dropdown-menu {
    border-radius: 3px;
    -webkit-box-shadow: none;
    box-shadow: none;
    border-radius: 0.25rem;
    width: 100%;
    margin-left: 10px !important;
    margin-bottom: 10px !important;
    padding: 0 !important;
    border: none;
    background-color: #0e1621;
    margin-top: 11px !important;
    overflow: hidden;
    border: 1px solid #c9c3c36b;
  }

  .dropdown-menu li {
    border-bottom: 1px solid #c9c3c36b;
  }

  .dropdown-menu li:nth-last-child(1) {
    border-bottom: none;
  }

  .dropdown-menu li .dropdown-item {
    padding: 10px 20px;
    font-weight: 500;
    font-size: 16px;
    color: #fff;
  }
}

.dropdown-item:focus,
.dropdown-item:hover {
  color: #fff;
  background-color: rgb(var(--main));
}

.navbar-toggler.header-button {
  border-color: transparent;
  color: #fff;
  background: transparent !important;
  padding: 0 !important;
  font-size: 23px !important;
  border: 0 !important;
  border-radius: 0 !important;
  -webkit-transition: 0.15s ease-in-out;
  transition: 0.15s ease-in-out;
  width: 30px;
}

.navbar-toggler.header-button:focus {
  -webkit-box-shadow: none !important;
  box-shadow: none !important;
}

.navbar-toggler.header-button[aria-expanded="true"] i::before {
  content: "\f00d";
}

.header-top {
  background-color: transparent;
  padding: 10px 0;
  border-bottom: 1px solid rgba(255, 255, 255, 0.9);
  padding: 10px 0;
  position: relative;
}

.login-registration-list {
  padding-left: 20px;
}

@media (max-width: 575px) {
  .login-registration-list {
    padding-left: 0;
  }
}

.login-registration-list__item {
  color: #fff;
  padding: 0 10px;
  position: relative;
}

.login-registration-list__item:last-child::before {
  display: none;
}

.login-registration-list__item::before {
  position: absolute;
  content: "";
  width: 1px;
  height: 15px;
  top: 50%;
  right: 0;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
  background-color: rgba(255, 255, 255, 0.5);
}

.login-registration-list__icon {
  font-size: 12px;
  text-align: center;
  margin-right: 5px;
  color: rgb(var(--main));
}

@media (max-width: 575px) {
  .login-registration-list__icon {
    width: 30px;
    height: 30px;
  }
}

.login-registration-list__link {
  color: #fff;
  font-weight: 400;
}

@media (max-width: 575px) {
  .login-registration-list__link {
    font-size: 15px;
  }
}

.login-registration-list__link:hover {
  color: rgb(var(--main));
}

@media (max-width: 991px) {
  .login-registration-list__link:hover {
    color: rgb(var(--main));
  }
}

.breadcumb {
  position: relative;
  z-index: 1;
  padding: 60px 0;
  position: relative;
}

@media (max-width: 991px) {
  .breadcumb {
    padding: 40px 0;
  }
}

@media (max-width: 767px) {
  .breadcumb {
    padding: 30px 0;
  }
}

.breadcumb.bg-overlay-one::before {
  opacity: 0.4;
}

.breadcumb__wrapper {
  text-align: center;
}

.breadcumb__title {
  margin-bottom: 0px;
  color: #fff;
}

.breadcumb__list {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
}

.breadcumb__item {
  color: #fff;
  padding: 0 5px;
  font-weight: 400;
  font-size: 16px;
}

@media (max-width: 575px) {
  .breadcumb__item {
    font-size: 15px;
  }
}

.breadcumb__item-text {
  color: rgb(var(--main));
}

.breadcumb__link {
  color: #fff;
  font-weight: 500;
}

.breadcumb__link:hover {
  color: rgb(var(--main));
}

.footer-area {
  background-color: #0e1621;
  margin-top: auto;
}

.footer-area.bg-img {
  background-size: contain;
  background-position: top center;
}

@media (max-width: 1199px) {
  .footer-area.bg-img {
    background-size: cover;
  }
}

.footer-area.bg-overlay-one::before {
  background-color: #0e1621;
  opacity: 0.93;
}

.footer-item__title {
  color: #fff;
  padding-bottom: 10px;
  margin-bottom: 25px;
  position: relative;
}

.footer-item__title::after {
  position: absolute;
  content: "";
  width: 30px;
  height: 3px;
  left: 0;
  bottom: -2px;
  background-color: rgb(var(--main));
}

.footer-item__logo {
  margin-bottom: 20px;
}

.footer-item__logo a img {
  max-width: 160px;
  max-height: 64px;
}

.footer-item .social-list {
  margin-top: 30px;
}

@media (max-width: 991px) {
  .footer-item .social-list {
    margin-top: 20px;
  }
}

@media (max-width: 575px) {
  .footer-item .social-list {
    margin-top: 15px;
  }
}

.footer-menu {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  margin: -5px 0;
}

.footer-menu__item {
  display: block;
  padding: 5px 0;
}

.footer-menu__link {
  color: #b9babb;
  font-size: 15px;
  padding: 0 0 0 15px;
  position: relative;
}

.footer-menu__link::before {
  content: "";
  position: absolute;
  left: 0;
  top: 50%;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
  width: 8px;
  height: 8px;
  background-color: rgb(var(--main));
}

.footer-menu__link:hover {
  color: rgb(var(--main));
  margin-left: 4px;
}

.footer-contact-menu {
  margin: -6px 0;
}

.footer-contact-menu__item {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  padding: 6px 0;
  align-items: center;
}

.footer-contact-menu__item-icon {
  width: 15px;
  color: rgb(var(--main));
  font-size: 20px;
}

.footer-contact-menu__item-content {
  width: calc(100% - 15px);
  padding-left: 15px;
}

.bottom-footer {
  background-color: #111b28;
  border-top: 1px solid rgba(255, 255, 255, 0.08);
}

@media (max-width: 400px) {
  .bottom-footer__eft {
    font-size: 15px;
  }
}

.bottom-footer__eft a {
  color: rgb(var(--main));
  font-weight: 600;
  padding: 0 5px;
}

.bottom-footer__eft a:hover {
  color: #fff;
}

.preloader {
  position: fixed;
  z-index: 999999;
  background-color: #000;
  width: 100%;
  height: 100%;
}

.loader-p {
  border: 0 solid transparent;
  border-radius: 50%;
  width: 150px;
  height: 150px;
  position: absolute;
  top: calc(50vh - 75px);
  left: calc(50vw - 75px);
}

.loader-p:before,
.loader-p:after {
  content: "";
  border: 1em solid rgb(var(--main));
  border-radius: 50%;
  width: inherit;
  height: inherit;
  position: absolute;
  top: 0;
  left: 0;
  -webkit-animation: loader 2s linear infinite;
  animation: loader 2s linear infinite;
  opacity: 0;
}

.loader-p:before {
  -webkit-animation-delay: 0.5s;
  animation-delay: 0.5s;
}

@-webkit-keyframes loader {
  0% {
    -webkit-transform: scale(0);
    transform: scale(0);
    opacity: 0;
  }

  50% {
    opacity: 1;
  }

  100% {
    -webkit-transform: scale(1);
    transform: scale(1);
    opacity: 0;
  }
}

@keyframes loader {
  0% {
    -webkit-transform: scale(0);
    transform: scale(0);
    opacity: 0;
  }

  50% {
    opacity: 1;
  }

  100% {
    -webkit-transform: scale(1);
    transform: scale(1);
    opacity: 0;
  }
}

.bg-img {
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
  width: 100%;
  height: 100%;
}

.bg-overlay-one {
  position: relative;
  isolation: isolate;
}

.bg-overlay-one::before {
  position: absolute;
  content: "";
  left: 0;
  top: 0;
  background-color: #0e1621;
  opacity: 0.9;
  width: 100%;
  height: 100%;
  z-index: -1;
}

.bg-overlay-two {
  position: relative;
  isolation: isolate;
}

.bg-overlay-two::before {
  position: absolute;
  content: "";
  left: 0;
  top: 0;
  background-color: rgb(var(--main));
  opacity: 0.8;
  width: 100%;
  height: 100%;
  z-index: -1;
}

.scroll-top {
  position: fixed;
  right: 40px;
  bottom: 30px;
  color: #fff;
  background-color: rgb(var(--main));
  width: 40px;
  height: 40px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  border-radius: 3px;
  font-size: 16px;
  z-index: 5;
  -webkit-transition: 0.5s;
  transition: 0.5s;
  cursor: pointer;
  visibility: hidden;
  opacity: 0;
  bottom: 210px;
}

@media (max-width: 767px) {
  .scroll-top {
    right: 20px;
    width: 35px;
    height: 35px;
    font-size: 15px;
  }
}

.scroll-top:hover {
  color: #fff;
  background-color: #f7a906;
}

.scroll-top.show {
  visibility: visible;
  opacity: 1;
  bottom: 30px;
}

.overlay-search-box .modal-header {
  border-bottom: 0;
  padding: 0;
}

.overlay-search-box .modal-content {
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -ms-flex-direction: row;
  flex-direction: row;
  width: 100%;
  pointer-events: auto;
  background-color: #000;
  background-clip: padding-box;
  border-radius: 0.3rem;
  outline: 0;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -ms-flex-line-pack: center;
  align-content: center;
}

.search-box {
  width: 80%;
  margin: 0 auto;
}

@media (max-width: 767px) {
  .search-box {
    width: 100%;
  }
}

.search-btn {
  background-color: transparent;
  color: #fff;
  font-weight: 500;
  font-size: 18px;
  position: absolute;
  top: 50%;
  right: 20px;
  -webkit-transform: translateY(-50%) rotate(-90deg);
  transform: translateY(-50%) rotate(-90deg);
}

.search-btn:hover {
  color: rgb(var(--main));
}

.toggle-search-box {
  margin-left: 15px;
  -webkit-box-ordinal-group: 2;
  -ms-flex-order: 1;
  order: 1;
}

@media (max-width: 991px) {
  .toggle-search-box {
    -webkit-box-ordinal-group: 0;
    -ms-flex-order: -1;
    order: -1;
    margin-left: auto;
    margin-right: 16px;
  }
}

.toggle-search-box button {
  background-color: transparent;
  color: #fff;
}

.search-overlay-close {
  background-color: rgb(var(--main));
  color: #fff;
  width: 40px;
  height: 40px;
  display: -ms-grid;
  display: grid;
  place-items: center;
  border-radius: 5px;
  position: absolute;
  top: 20px;
  right: 20px;
  font-size: 20px;
  -webkit-transition: 0.2s linear;
  transition: 0.2s linear;
}

.search-overlay-close:hover {
  background-color: #c58704;
}

.filter {
  padding: 60px 30px;
  z-index: 2;
}

@media (max-width: 575px) {
  .filter {
    padding: 40px 20px;
  }
}

@media (max-width: 450px) {
  .filter {
    padding: 40px 0px;
  }
}

.filter-item {
  position: relative;
}

.filter-icon i {
  position: absolute;
  left: 1px;
  top: 50%;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
  background-color: #fff;
  height: 48px;
  width: 48px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  border-radius: 0 5px 5px 0;
  pointer-events: none;
  z-index: 1;
  color: rgb(var(--main));
}

.filter-select {
  height: 50px;
  position: relative;
  padding-left: 50px;
}

.filter-select:focus {
  -webkit-box-shadow: none;
  box-shadow: none;
}

::-moz-selection {
  color: #fff;
  background: rgb(var(--main));
}

::selection {
  color: #fff;
  background: rgb(var(--main));
}

::-webkit-scrollbar {
  width: 10px;
  height: 10px;
}

::-webkit-scrollbar-button {
  width: 0px;
  height: 0px;
}

::-webkit-scrollbar-thumb {
  background-color: rgba(var(--main), 0.5);
  border: 0px solid transparent;
  border-radius: 1px;
}

.social-list {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  margin: 0 -5px;
}

.social-list__item {
  margin: 0 5px;
}

.social-list__link {
  width: 40px;
  height: 40px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  border-radius: 50%;
  position: relative;
  overflow: hidden;
  background-color: rgba(255, 255, 255, 0.1);
  -webkit-transition: 0.3s;
  transition: 0.3s;
  cursor: pointer;
  color: rgba(255, 255, 255, 0.8);
}

.social-list__link.active,
.social-list__link:hover {
  background-color: rgb(var(--main));
  color: #fff !important;
  border-color: rgb(var(--main)) !important;
}

@media (max-width: 767px) {
  .social-list__link {
    width: 35px;
    height: 35px;
    font-size: 14px;
  }
}

.text-list.style-two {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.text-list__item {
  padding: 15px 0px 15px 20px;
  color: #b9babb;
  position: relative;
  color: #fff;
  text-align: left;
  border-bottom: 1px dashed rgba(255, 255, 255, 0.15);
  font-size: 15px;
  font-weight: 400;
}

.text-list__item:last-child {
  border-bottom: 0;
}

.text-list__item::before,
.text-list__item::after {
  position: absolute;
  content: "";
  width: 6px;
  height: 6px;
  border-radius: 50%;
  left: 0;
  top: 50%;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
  background-color: #fff;
}

.text-list__item::after {
  width: 14px;
  height: 14px;
  background-color: rgba(var(--main), 0.4);
  left: -4px;
}

.text-list__item-icon {
  color: rgb(var(--main));
  font-size: 15px;
  margin-right: 5px;
  width: 20px;
}

.blog-sidebar {
  background-color: rgba(255, 255, 255, 0.1);
  padding: 30px 20px;
  border-radius: 5px;
  position: sticky;
  top: 92px;
}

@media (max-width: 575px) {
  .blog-sidebar {
    padding: 20px 15px;
  }
}

.blog-sidebar__title {
  position: relative;
  padding-bottom: 10px;
  color: #fff;
}

.blog-sidebar__title::before {
  position: absolute;
  content: "";
  width: 60px;
  height: 2px;
  background-color: rgb(var(--main));
  left: 0;
  bottom: 0px;
}

.latest-blog {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  margin-bottom: 20px;
}

.latest-blog:last-of-type {
  margin-bottom: 0;
}

.latest-blog__thumb {
  width: 80px;
  height: 80px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  border-radius: 5px;
  overflow: hidden;
}

.latest-blog__thumb a {
  display: block;
  height: 100%;
  width: 100%;
}

.latest-blog__thumb img {
  width: 100%;
  height: 100%;
  -o-object-fit: cover;
  object-fit: cover;
}

.latest-blog__content {
  width: calc(100% - 100px);
  padding-left: 15px;
}

.latest-blog__title {
  margin-bottom: 5px;
}

.latest-blog__title a {
  color: #fff;
  font-weight: 500;
  font-size: 16px;
}

.latest-blog__title a:hover {
  color: rgb(var(--main));
}

.comment-list__item:last-child .comment-list__content {
  border-bottom: none;
}

.comment-list__thumb {
  width: 80px;
  height: 80px;
  border-radius: 50%;
  overflow: hidden;
}

@media (max-width: 767px) {
  .comment-list__thumb {
    width: 60px;
    height: 60px;
  }
}

.comment-list__content {
  width: calc(100% - 80px);
  padding-left: 15px;
  border-bottom: 1px solid rgba(255, 255, 255, 0.15);
  padding-bottom: 30px;
  margin-bottom: 30px;
}

@media (max-width: 767px) {
  .comment-list__content {
    width: calc(100% - 60px);
    padding-bottom: 25px;
    margin-bottom: 25px;
  }
}

@media (max-width: 575) {
  .comment-list__content {
    padding-bottom: 20px;
    margin-bottom: 20px;
    width: 100%;
    padding-left: 0;
    margin-top: 15px;
  }
}

.comment-list__name {
  font-size: 20px;
  margin-bottom: 5px;
  color: #fff;
}

@media (max-width: 767) {
  .comment-list__name {
    font-size: 17px;
  }
}

.comment-list__time {
  margin-bottom: 15px;
}

.comment-list__time-icon {
  color: rgb(var(--main));
  font-size: 15px;
  margin-right: 5px;
}

.comment-list__reply {
  margin-top: 10px;
}

.comment-list__reply-text {
  color: #fff;
  font-weight: 400;
}

.comment-list__reply:hover .comment-list__reply-icon {
  color: rgb(var(--main));
}

.comment-list__reply-icon {
  font-size: 14px;
  -webkit-transform: scaleX(-1);
  transform: scaleX(-1);
  margin-left: 5px;
}

@media (max-width: 767px) {
  .comment-list__reply-icon {
    font-size: 12px;
  }
}

.comment-list .comment-list {
  margin-left: 70px;
}

.comment-list
  .comment-list.style-right
  .comment-list__item:last-child
  .comment-list__content {
  border-bottom: 1px solid rgba(255, 255, 255, 0.15);
}

@media (max-width: 575px) {
  .comment-list .comment-list {
    margin-left: 40px;
  }
}

.sidenav-bar {
  margin-bottom: 20px;
}

.sidenav-bar__icon {
  font-size: 20px;
  cursor: pointer;
}

.dashboard-sidebar-menu {
  overflow-y: auto;
  background-color: rgba(255, 255, 255, 0.05);
  padding: 20px;
  border-radius: 5px;
}

@media (max-width: 991px) {
  .dashboard-sidebar-menu {
    position: fixed;
    z-index: 99;
    background-color: #232a35;
    width: 320px;
    left: 0;
    top: 0px;
    transform: translateX(-100%);
    transition: 0.2s linear;
    height: 100%;
    border-radius: 0 5px 5px 0;
  }

  .dashboard-sidebar-menu.sidebar_show {
    transform: translateX(0);
  }
}

@media (max-width: 991px) {
  .sidebar-overlay {
    position: fixed;
    content: "";
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    background-color: rgba(0, 0, 0, 0.752);
    z-index: 99;
    opacity: 0;
    visibility: hidden;
    transition: 0.2s linear;
  }

  .sidebar-overlay.show_overlay {
    visibility: visible;
    opacity: 1;
  }
}

.dashboard-sidebar__close-icon {
  position: absolute;
  right: 25px;
  top: 20px;
  width: 35px;
  height: 35px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 3px;
  background-color: #f75959;
  color: #fff;
  font-size: 18px;
  transition: 0.2s linear;
}

.dashboard-sidebar__close-icon:hover {
  background-color: #f54242;
}

.dashboard-sidebar-menu::-webkit-scrollbar {
  width: 3px;
}

.dashboard-menu {
  margin: -5px 0;
}

@media (max-width: 991px) {
  .dashboard-menu {
    margin-top: 30px;
  }
}

.dashboard-menu.sub-menu {
  display: none;
  margin: -0px 0;
  margin-top: 0px;
  margin-bottom: 7px;
  margin-left: 30px;
}

.has-submenu a.active + .dashboard-menu.sub-menu {
  display: block;
}

.dashboard-menu__item {
  padding: 5px 0;
  position: relative;
}

.dashboard-menu__link {
  color: rgba(255, 255, 255, 0.9);
  background-color: transparent;
  width: 100%;
  padding: 3px 5px;
  font-weight: 300;
  border-radius: 5px;
}

.dashboard-menu__link:hover {
  color: rgb(var(--main));
}

/* @media (max-width:991px) {
  .dashboard-menu__link:hover {
    color: #fff;
  }
} */

.dashboard-menu__link.active .dashboard-menu__link-arrow {
  transform: rotate(90deg);
}

.dashboard-menu__link.active {
  color: rgb(var(--main));
}

.dashboard-menu__link-icon {
  font-size: 15px;
  margin-right: 5px;
}

.dashboard-menu__link-arrow {
  float: right;
  -webkit-transition: 0.1s linear;
  transition: 0.1s linear;
  font-size: 13px;
}

.dashboard-menu__link-arrow.show {
  -webkit-transform: rotate(90deg);
  transform: rotate(90deg);
}

.dashboard-card {
  background-color: rgba(255, 255, 255, 0.05);
  padding: 20px;
  border-radius: 5px;
  position: relative;
  z-index: 1;
  overflow: hidden;
}

@media (max-width: 1199px) {
  .dashboard-card {
    padding: 15px;
  }
}

.dashboard-card::before {
  position: absolute;
  content: "";
  width: 45%;
  height: 95%;
  right: -20%;
  bottom: -50%;
  background-color: transparent;
  border: 20px solid rgba(255, 255, 255, 0.03);
  border-radius: 50%;
  z-index: -1;
  aspect-ratio: 1;
}

.dashboard-card::after {
  position: absolute;
  content: "";
  width: 80px;
  height: 80px;
  right: -30px;
  top: -30px;
  background-color: rgba(255, 255, 255, 0.03);
  border-radius: 50%;
  z-index: -1;
  aspect-ratio: 1;
}

.dashboard-card__thumb-title {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
}

.dashboard-card__thumb {
  width: 60px;
  height: 60px;
  border: 3px solid #a14ff963;
  border-radius: 50%;
  overflow: hidden;
}

.dashboard-card__title {
  margin-bottom: 0;
  width: calc(100% - 60px);
  padding-left: 15px;
}

.dashboard-card__Status {
  margin-bottom: 0;
}

.dashboard-card__content {
  margin-top: 10px;
}

.dashboard-card__amount {
  margin-bottom: 5px;
}

.banner {
  padding: 230px 0 230px;
  background-color: #060e1b;
}

@media (max-width: 1199px) {
  .banner {
    padding: 180px 0 180px;
  }
}

@media (max-width: 991px) {
  .banner {
    padding: 80px 0 205px;
  }
}

@media (max-width: 767px) {
  .banner {
    padding: 70px 0 195px;
  }
}

@media (max-width: 575px) {
  .banner {
    padding: 50px 0 175px;
  }
}

.banner::before {
  background: -webkit-gradient(
    linear,
    left top,
    right top,
    from(#0e1621),
    color-stop(60.71%, transparent)
  );
  background: linear-gradient(to right, #0e1621, transparent 60.71%);
  opacity: 1;
}

@media (max-width: 991px) {
  .banner::before {
    background: linear-gradient(to right, #0e16219e, #0000008a 60.71%);
    opacity: 1;
  }
}

.banner.bg-img {
  background-size: contain;
  background-position: right center;
}

@media (max-width: 991px) {
  .banner.bg-img {
    background-size: cover;
    background-position: center center;
  }
}

.banner-content__title {
  margin-bottom: 15px;
}

.banner-content__desc {
  color: #fff;
  max-width: 500px;
  margin-bottom: 50px;
}

@media (max-width: 991px) {
  .banner-content__desc {
    max-width: 100%;
    margin-bottom: 40px;
  }
}

@media (max-width: 767px) {
  .banner-content__desc {
    margin-bottom: 30px;
  }
}

.calculator {
  position: relative;
  z-index: 1;
}

.banner-calculator {
  background-color: #0e1621;
  border: 1px solid rgba(255, 255, 255, 0.2);
  padding: 50px 30px;
  border-radius: 10px;
  position: relative;
  z-index: 1;
  overflow: hidden;
  margin-top: -115px;
}

@media (max-width: 991px) {
  .banner-calculator {
    padding: 40px 20px;
    margin-top: -125px;
  }
}

@media (max-width: 575px) {
  .banner-calculator {
    padding: 30px 15px;
  }
}

.banner-calculator__title {
  text-align: center;
}

.banner-calculator__content {
  text-align: center;
}

.banner-calculator__text {
  color: rgb(var(--main));
  margin-bottom: 5px;
}

.banner-calculator__number {
  margin-bottom: 0;
}

.work {
  background-color: rgba(255, 255, 255, 0.01);
}

.work-item {
  background-color: rgba(255, 255, 255, 0.08);
  padding: 30px 20px;
  text-align: center;
  border-radius: 8px;
  position: relative;
  overflow: hidden;
}

@media (max-width: 991px) {
  .work-item {
    padding: 25px 15px;
  }
}

.work-item:hover::before {
  width: 100%;
}

.work-item:hover::after {
  height: 100%;
}

.work-item:hover .work-item__border::before {
  height: 100%;
}

.work-item:hover .work-item__border::after {
  width: 100%;
}

.work-item::before {
  position: absolute;
  content: "";
  width: 0;
  height: 2px;
  left: 0;
  top: 0;
  background-color: rgb(var(--main));
  -webkit-transition: 0.3s linear;
  transition: 0.3s linear;
}

.work-item::after {
  position: absolute;
  content: "";
  width: 2px;
  height: 0;
  background-color: rgb(var(--main));
  -webkit-transition: 0.3s linear;
  transition: 0.3s linear;
  top: 0;
  left: 0;
}

.work-item:hover .work-item__icon {
  background-color: #fff;
  color: rgb(var(--main));
}

.work-item:hover .work-item__number {
  color: rgba(255, 255, 255, 0.5);
  font-size: 60px;
}

.work-item__border {
  width: 100%;
  height: 100%;
  position: absolute;
  right: 0;
  bottom: 0;
  z-index: -1;
}

.work-item__border::before {
  position: absolute;
  content: "";
  width: 2px;
  height: 0px;
  right: 0;
  bottom: 0;
  background-color: rgb(var(--main));
  -webkit-transition: 0.3s linear;
  transition: 0.3s linear;
}

.work-item__border::after {
  position: absolute;
  content: "";
  width: 0;
  height: 2px;
  background-color: rgb(var(--main));
  -webkit-transition: 0.3s linear;
  transition: 0.3s linear;
  bottom: 0;
  right: 0;
}

.work-item__number {
  position: absolute;
  right: 20px;
  top: 20px;
  font-size: 50px;
  font-weight: 700;
  color: rgba(255, 255, 255, 0.3);
  -webkit-transition: 0.2s linear;
  transition: 0.2s linear;
}

@media (max-width: 991px) {
  .work-item__number {
    font-size: 40px;
  }
}

@media (max-width: 767px) {
  .work-item__number {
    font-size: 30px;
  }
}

.work-item__icon {
  font-size: 40px;
  color: #fff;
  width: 70px;
  height: 70px;
  border-radius: 50%;
  background-color: rgb(var(--main));
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  margin-left: auto;
  margin-right: auto;
  -webkit-transition: 0.2s linear;
  transition: 0.2s linear;
}

@media (max-width: 767px) {
  .work-item__icon {
    font-size: 30px;
    width: 60px;
    height: 60px;
  }
}

.work-item__title {
  margin-top: 25px;
  margin-bottom: 15px;
}

.about-thumb {
  position: relative;
}

.about-thumb img {
  width: 100%;
  height: 100%;
  -o-object-fit: cover;
  object-fit: cover;
}

.about-thumb__coin {
  position: absolute;
  top: 0;
  left: 45%;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
  -webkit-animation: coin 5s linear infinite;
  animation: coin 5s linear infinite;
}

.about-thumb__coin img {
  max-width: 80%;
}

@media (max-width: 400px) {
  .about-thumb__coin img {
    max-width: 50%;
  }
}

@-webkit-keyframes coin {
  0% {
    -webkit-transform: translateY(40px);
    transform: translateY(40px);
  }

  50% {
    -webkit-transform: translateY(0px);
    transform: translateY(0px);
  }

  100% {
    -webkit-transform: translateY(40px);
    transform: translateY(40px);
  }
}

@keyframes coin {
  0% {
    -webkit-transform: translateY(40px);
    transform: translateY(40px);
  }

  50% {
    -webkit-transform: translateY(0px);
    transform: translateY(0px);
  }

  100% {
    -webkit-transform: translateY(40px);
    transform: translateY(40px);
  }
}

.about-item {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  margin-bottom: 30px;
}

.about-item:last-of-type {
  margin-bottom: 0px;
}

.about-item__icon {
  width: 60px;
  height: 60px;
  background-color: rgba(255, 255, 255, 0.1);
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  color: rgb(var(--main));
  font-size: 24px;
  border-radius: 5px 50%;
  border-radius: 50%;
}

.about-item__content {
  width: calc(100% - 60px);
  padding-left: 20px;
}

.about-item__title {
  margin-bottom: 5px;
}

.subscription {
  position: relative;
  z-index: 1;
  overflow: hidden;
}

.subscription::after {
  position: absolute;
  content: "";
  left: -25px;
  top: -24px;
  background-color: rgba(var(--main), 0.2);
  opacity: 0.9;
  width: 100px;
  height: 100px;
  z-index: -1;
  border-radius: 50%;
}

@media (max-width: 575px) {
  .subscription::after {
    display: none;
  }
}

.subscription::before {
  position: absolute;
  content: "";
  right: -25px;
  bottom: -25px;
  background-color: rgba(var(--main), 0.2);
  opacity: 0.9;
  width: 100px;
  height: 100px;
  z-index: -1;
  border-radius: 50%;
}

@media (max-width: 575px) {
  .subscription::before {
    display: none;
  }
}

.subscription-form {
  background-color: rgba(255, 255, 255, 0.08);
  padding: 10px;
  border-radius: 5px;
  border-radius: 50px;
}

@media (max-width: 575px) {
  .subscription-form {
    padding: 10px;
  }
}

.subscription-form .input--group {
  border-radius: 30px;
}

.subscription-form .form--control {
  border-radius: 30px;
  padding: 0px 138px 0 15px;
}

.subscription-background {
  position: absolute;
  left: 0;
  background-color: rgba(255, 255, 255, 0.06);
  height: 100%;
  width: 50%;
  top: 0;
  z-index: -1;
}

@media (max-width: 991px) {
  .subscription-background {
    width: 100%;
  }
}

.subscription-button {
  position: absolute;
  top: 50%;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
  right: 5px;
  border-radius: 30px;
}

.transaction {
  position: relative;
  overflow: hidden;
}

.transaction::before {
  position: absolute;
  content: "";
  width: 550px;
  height: 200px;
  background-color: rgba(255, 255, 255, 0.1);
  left: 50%;
  -webkit-transform: translateX(-50%);
  transform: translateX(-50%);
  bottom: -125px;
  aspect-ratio: 1;
  border-radius: 50%;
  z-index: -1;
  -webkit-animation: transaction 30s linear infinite;
  animation: transaction 30s linear infinite;
}

@media (max-width: 991px) {
  .transaction::before {
    width: 450px;
    height: 150px;
    bottom: -75px;
  }
}

@media (max-width: 575px) {
  .transaction::before {
    width: 350px;
    height: 100px;
    bottom: -50px;
  }
}

@-webkit-keyframes transaction {
  0% {
    -webkit-transform: translateX(300px);
    transform: translateX(300px);
  }

  50% {
    -webkit-transform: translateX(0px);
    transform: translateX(0px);
  }

  100% {
    -webkit-transform: translateX(300px);
    transform: translateX(300px);
  }
}

@keyframes transaction {
  0% {
    -webkit-transform: translateX(300px);
    transform: translateX(300px);
  }

  50% {
    -webkit-transform: translateX(0px);
    transform: translateX(0px);
  }

  100% {
    -webkit-transform: translateX(300px);
    transform: translateX(300px);
  }
}

.user-info__name {
  margin-bottom: 0;
}

@media (max-width: 991px) {
  .user-info__name {
    margin-left: 15px;
  }
}

@media (max-width: 767px) {
  .user-info__name {
    margin-left: 10px;
  }
}

.services-left {
  background-color: rgb(var(--main));
}

.service-card {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.service-card:hover .service-card__icon {
  background-color: #fff;
  color: rgb(var(--main));
}

.service-card:hover .service-card__content {
  background-color: rgb(var(--main));
}

.service-card__icon {
  width: 60px;
  height: 60px;
  background-color: rgb(var(--main));
  border-radius: 50%;
  color: #fff;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  font-size: 25px;
  margin-right: -30px;
  z-index: 1;
  -webkit-transition: 0.2s linear;
  transition: 0.2s linear;
}

@media (max-width: 400px) {
  .service-card__icon {
    width: 50px;
    height: 50px;
  }
}

.service-card__content {
  width: calc(100% - 60px);
  background-color: rgba(255, 255, 255, 0.08);
  padding: 20px 20px 20px 50px;
  border-radius: 5px;
  -webkit-transition: 0.2s linear;
  transition: 0.2s linear;
}

@media (max-width: 991px) {
  .service-card__content {
    padding: 20px 20px 20px 40px;
  }
}

@media (max-width: 400px) {
  .service-card__content {
    padding: 15px 15px 10px 35px;
    width: calc(100% - 50px);
  }
}

.service-card__title {
  margin-bottom: 5px;
}

.service-card__desc {
  color: rgba(255, 255, 255, 0.75);
  font-size: 15px;
}

.price-item {
  text-align: center;
  background-color: rgba(255, 255, 255, 0.05);
  border-radius: 5px;
  -webkit-transition: 0.2s linear;
  transition: 0.2s linear;
  border: 1px solid rgba(255, 255, 255, 0.15);
  height: 100%;
  display: flex;
  flex-direction: column;
}

.price-item__header {
  background-color: rgb(var(--main));
  padding: 20px;
  position: relative;
  overflow: hidden;
  border-radius: 5px 5px 0 0;
}

.price-item__header::before {
  position: absolute;
  content: "";
  height: 100px;
  width: 100px;
  left: 0%;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  top: 0%;
  background-color: rgba(0, 0, 0, 0.05);
  border-radius: 50%;
}

.price-item__header::after {
  position: absolute;
  content: "";
  height: 100px;
  width: 100px;
  left: 100%;
  top: 100%;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  background-color: rgba(0, 0, 0, 0.05);
  border-radius: 50%;
}

.price-item__title {
  margin-bottom: 5px;
}

.price-item__thumb {
  width: 60px;
  height: 60px;
  overflow: hidden;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 10px;
}

.price-item__thumb img {
  width: 100%;
  height: 100%;
  -o-object-fit: cover;
  object-fit: cover;
}

.price-item__content {
  padding: 20px;
  display: flex;
  flex-direction: column;
  height: 100%;
}

.price-item__body {
  border-top: 0;
  border-radius: 0 0 5px 5px;
}

.price-item__price-month {
  font-size: 15px;
  font-weight: 400;
}

.price-item__button {
  margin-top: auto;
}

.price-item__button .btn--base {
  width: 100%;
}

.blog-item {
  padding: 20px;
  background-color: rgba(255, 255, 255, 0.1);
  border-radius: 8px;
  overflow: hidden;
}

.blog-item:hover .blog-item__thumb.blog-details img {
  -webkit-transform: unset;
  transform: unset;
}

.blog-item:hover .blog-item__thumb img {
  -webkit-transform: scale(1.2);
  transform: scale(1.2);
}

.blog-item__thumb {
  position: relative;
  overflow: hidden;
  border-radius: 8px;
}

.blog-item__thumb.blog-details {
  max-height: 400px;
  overflow: hidden;
}

.blog-item__thumb-link {
  display: block;
}

.blog-item__thumb-link img {
  width: 100%;
  height: 100%;
  -o-object-fit: cover;
  object-fit: cover;
  border-radius: 8px;
  -webkit-transition: 0.2s linear;
  transition: 0.2s linear;
}

.blog-item__thumb img {
  width: 100%;
  height: 100%;
  -o-object-fit: cover;
  object-fit: cover;
  border-radius: 8px;
  -webkit-transition: 0.2s linear;
  transition: 0.2s linear;
}

.blog-item__date {
  background-color: rgb(var(--main));
  color: #fff;
  padding: 6px 20px;
  position: absolute;
  right: 0;
  bottom: 0;
  border-radius: 8px 0;
  font-size: 18px;
}

.blog-item__date-icon {
  font-size: 13px;
  margin-right: 5px;
}

.blog-item__title {
  margin-top: 20px;
  margin-bottom: 10px;
}

.blog-item__title-link {
  color: #fff;
}

.blog-item__desc {
  margin-bottom: 20px;
  text-overflow: ellipsis;
  overflow: hidden;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  display: -webkit-box;
  color: rgba(255, 255, 255, 0.7);
}

.blog-item__desc.blog-details {
  -webkit-line-clamp: unset;
  display: block;
  margin-bottom: 30px;
}

.blog-item__desc.blog-details:last-of-type {
  margin-bottom: 0;
}

.chooseus {
  z-index: 1;
  overflow: hidden;
}

.chooseus-thumb {
  z-index: -1;
}

.chooseus-thumb img {
  width: 100%;
  height: 100%;
  -o-object-fit: cover;
  object-fit: cover;
}

.chooseus-card {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  margin-bottom: 30px;
}

@media (max-width: 1199px) {
  .chooseus-card {
    display: block;
    text-align: center;
  }
}

@media (max-width: 767px) {
  .chooseus-card {
    background-color: #000;
    padding: 30px 20px;
    border-radius: 5px;
  }
}

@media (max-width: 575px) {
  .chooseus-card {
    background-color: #000;
    padding: 25px 15px;
  }
}

.chooseus-card:nth-child(2) {
  margin-left: 30px;
}

@media (max-width: 1199px) {
  .chooseus-card:nth-child(2) {
    margin-left: 0;
  }
}

.chooseus-card:last-of-type {
  margin-bottom: 0;
}

.chooseus-card:nth-child(even) .chooseus-card__icon {
  background-color: rgba(255, 255, 255, 0.1);
  color: rgb(var(--main));
}

@media (max-width: 767px) {
  .chooseus-card:nth-child(even) .chooseus-card__icon {
    color: #fff;
    background-color: rgb(var(--main));
  }
}

.chooseus-card.style-two {
  -webkit-box-orient: horizontal;
  -webkit-box-direction: reverse;
  -ms-flex-direction: row-reverse;
  flex-direction: row-reverse;
}

.chooseus-card.style-two:nth-child(2) {
  margin-right: 30px;
}

@media (max-width: 1199px) {
  .chooseus-card.style-two:nth-child(2) {
    margin-right: 0;
  }
}

.chooseus-card.style-two .chooseus-card__content {
  width: calc(100% - 60px);
  padding-right: 20px;
  padding-left: 0px;
  text-align: right;
}

@media (max-width: 1199px) {
  .chooseus-card.style-two .chooseus-card__content {
    text-align: center;
    width: 100%;
    padding-right: 0px;
  }
}

.chooseus-card__icon {
  width: 60px;
  height: 60px;
  background-color: rgba(var(--main), 0.9);
  color: #fff;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  font-size: 25px;
  border-radius: 50%;
}

@media (max-width: 1199px) {
  .chooseus-card__icon {
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 20px;
  }
}

@media (max-width: 767px) {
  .chooseus-card__icon {
    font-size: 20px;
  }
}

.chooseus-card__content {
  width: calc(100% - 60px);
  padding-left: 20px;
}

@media (max-width: 1199px) {
  .chooseus-card__content {
    width: 100%;
    padding-right: 0px;
  }
}

.chooseus-card__title {
  margin-bottom: 10px;
}

.testimonails-item-wrapper {
  margin-top: 0px;
}

.testimonails-item-wrapper .slick-center .testimonails-item__content {
  background-color: rgba(255, 255, 255, 0.15);
}

.testimonails-item {
  text-align: center;
}

.testimonails-item__content {
  background-color: rgba(255, 255, 255, 0.07);
  padding: 30px 20px 70px;
  border-radius: 8px;
}

@media (max-width: 767px) {
  .testimonails-item__content {
    padding: 20px 15px 60px;
  }
}

.testimonails-item__icon {
  font-size: 70px;
  color: rgb(var(--main));
  line-height: 1;
  margin-bottom: 20px;
}

@media (max-width: 767px) {
  .testimonails-item__icon {
    font-size: 60px;
  }
}

@media (max-width: 575px) {
  .testimonails-item__icon {
    font-size: 50px;
    margin-bottom: 15px;
  }
}

.testimonails-item__desc {
  color: rgba(255, 255, 255, 0.7);
}

.testimonails-item__thumb {
  width: 80px;
  height: 80px;
  border-radius: 50%;
  overflow: hidden;
  margin-left: auto;
  margin-right: auto;
}

@media (max-width: 767px) {
  .testimonails-item__thumb {
    width: 70px;
    height: 70px;
  }
}

.testimonails-item__thumb img {
  width: 100%;
  height: 100%;
  -o-object-fit: cover;
  object-fit: cover;
}

.testimonails-item__info {
  margin-top: -40px;
}

.testimonails-item__name {
  margin-bottom: 0;
  margin-top: 15px;
}

.testimonails-item__designation {
  font-size: 14px;
}

.contact-information {
  background-color: rgb(30 38 49);
  border-radius: 10px;
  border: 1px solid rgba(255, 255, 255, 0.03);
  padding: 30px;
}

@media (max-width: 991px) {
  .contact-information {
    padding: 30px 15px;
  }
}

.contact-title {
  margin-bottom: 30px;
  padding-bottom: 10px;
  position: relative;
}

.contact-title::before {
  position: absolute;
  content: "";
  left: 0;
  bottom: 0;
  width: 60px;
  height: 2px;
  background-color: rgb(var(--main));
}

.contact-information__item {
  margin-bottom: 25px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
}

.contact-information__item:last-of-type {
  margin-bottom: 0;
}

.contact-information__item-icon {
  color: #fff;
  font-size: 18px;
  width: 50px;
  height: 50px;
  background-color: rgba(255, 255, 255, 0.1);
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  border-radius: 50%;
  background-color: rgb(var(--main));
  color: #fff;
}

@media (max-width: 575px) {
  .contact-information__item-icon {
    font-size: 16px;
    width: 45px;
    height: 45px;
  }
}

.contact-information__content {
  width: calc(100% - 50px);
  padding-left: 20px;
}

@media (max-width: 991px) {
  .contact-information__content {
    padding-left: 15px;
  }
}

@media (max-width: 575px) {
  .contact-information__content {
    width: calc(100% - 45px);
    padding-left: 10px;
  }
}

.contact-information__title {
  margin-bottom: 5px;
}

.contact-form {
  background-color: rgb(30 38 49);
  padding: 30px;
  border-radius: 10px;
  border: 1px solid rgba(255, 255, 255, 0.03);
}

@media (max-width: 991px) {
  .contact-form {
    padding: 30px 15px;
  }
}

.follow-us {
  background-color: rgba(255, 255, 255, 0.05);
  margin-top: 30px;
  padding: 30px;
  border-radius: 10px;
  border: 1px solid rgba(255, 255, 255, 0.03);
}

@media (max-width: 991px) {
  .follow-us {
    padding: 30px 15px;
  }
}

.account {
  position: relative;
  z-index: 1;
  overflow: hidden;
}

.account::before {
  position: absolute;
  content: "";
  width: 20%;
  height: 100%;
  left: -12%;
  top: -50%;
  border-radius: 60%;
  background-color: rgba(255, 255, 255, 0.1);
  z-index: -1;
  -webkit-animation: account1 30s linear infinite;
  animation: account1 30s linear infinite;
}

@media (max-width: 575px) {
  .account::before {
    display: none;
  }
}

.account::after {
  position: absolute;
  content: "";
  width: 200px;
  height: 200px;
  right: 0;
  top: 0%;
  border-radius: 50%;
  background-color: rgba(255, 255, 255, 0.1);
  z-index: -1;
  -webkit-animation: account2 4s linear infinite;
  animation: account2 4s linear infinite;
}

@-webkit-keyframes account1 {
  0% {
    -webkit-transform: translateY(100%) scale(1);
    transform: translateY(100%) scale(1);
  }

  50% {
    -webkit-transform: translateY(0%) scale(0.2);
    transform: translateY(0%) scale(0.2);
  }

  100% {
    -webkit-transform: translateY(100%) scale(1);
    transform: translateY(100%) scale(1);
  }
}

@keyframes account1 {
  0% {
    -webkit-transform: translateY(100%) scale(1);
    transform: translateY(100%) scale(1);
  }

  50% {
    -webkit-transform: translateY(0%) scale(0.2);
    transform: translateY(0%) scale(0.2);
  }

  100% {
    -webkit-transform: translateY(100%) scale(1);
    transform: translateY(100%) scale(1);
  }
}

@-webkit-keyframes account2 {
  0% {
    -webkit-transform: scale(0);
    transform: scale(0);
    background-color: rgb(var(--main));
  }

  50% {
    -webkit-transform: scale(0.5);
    transform: scale(0.5);
    background-color: rgba(255, 255, 255, 0.5);
    opacity: 0.8;
  }

  100% {
    -webkit-transform: scale(1);
    transform: scale(1);
    background-color: rgba(255, 255, 255, 0.1);
    opacity: 0;
  }
}

@keyframes account2 {
  0% {
    -webkit-transform: scale(0);
    transform: scale(0);
    background-color: rgb(var(--main));
  }

  50% {
    -webkit-transform: scale(0.5);
    transform: scale(0.5);
    background-color: rgba(255, 255, 255, 0.5);
    opacity: 0.8;
  }

  100% {
    -webkit-transform: scale(1);
    transform: scale(1);
    background-color: rgba(255, 255, 255, 0.1);
    opacity: 0;
  }
}

.account-content__thumb {
  display: -ms-grid;
  display: grid;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  position: relative;
}

.account-content__thumb img {
  width: auto;
  height: auto;
  -o-object-fit: contain;
  object-fit: contain;
  margin: 0 auto;
}

.account-content__logo {
  position: absolute;
  top: 50px;
  -webkit-transform: translateX(-50%);
  transform: translateX(-50%);
  left: 50%;
}

.checkbox__text {
  color: rgba(255, 255, 255, 0.8);
  display: inline;
}

@media (max-width: 575px) {
  .checkbox__text {
    font-size: 15px;
  }
}

.checkbox__link {
  display: inline;
}

@media (max-width: 575px) {
  .checkbox__link {
    font-size: 15px;
  }
}

.checkbox__forgot-pass {
  display: inline;
}

@media (max-width: 575px) {
  .checkbox__forgot-pass {
    font-size: 15px;
  }
}

@media (max-width: 575px) {
  .have-account__text {
    font-size: 15px;
  }
}

.have-account__link {
  display: inline;
}

@media (max-width: 575px) {
  .have-account__link {
    font-size: 15px;
  }
}

.append-icon--btn,
.input-group-text {
  background-color: rgb(var(--main));
  border: none;
  color: #fff;
}

.list-group-item {
  background-color: transparent;
  color: #fff;
  border-color: rgb(255 255 255 / 19%);
}

.language-box .custom--select {
  height: 37px;
}

@media (max-width: 375px) {
  .language-box .custom--select {
    padding: 3px 5px;
  }
}

.input-group-text.btn--danger {
  background-color: #f75959;
  color: #fff;
}

.input-group-text.btn--danger:hover {
  color: #fff;
  background-color: #ad2b2b;
}

input[name="search"] {
  border: 1px solid rgb(50 57 67);
}

/* ====================== Custom Check Box ================= */
.form--check .form-check-input {
  box-shadow: none;
  background-color: transparent;
  box-shadow: none !important;
  border: 0;
  border: 1px solid rgb(var(--main));
  position: relative;
  border-radius: 3px;
  width: 16px;
  height: 16px;
}

.form--check .form-check-input:checked {
  background-color: rgb(var(--main)) !important;
  border-color: rgb(var(--main)) !important;
  box-shadow: none;
}

.form--check .form-check-input[type="checkbox"] {
  background-image: none;
}

.form--check .form-check-input:checked::before {
  position: absolute;
  content: "\f00c";
  font-family: "Font Awesome 5 Free";
  font-weight: 900;
  color: #fff;
  font-size: 11px;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

@media (max-width: 991px) {
  .subscription-content__title {
    text-align: center;
  }

  .transaction-content.right {
    margin-top: 20px;
  }

  .transaction-content.right .transaction-content__title {
    text-align: left !important;
  }
}

@media (max-width: 321px) {
  .subscription-form .form--control {
    padding: 0px 123px 0 15px;
  }

  .user-btn-group .btn--danger {
    padding: 5px 10px !important;
  }
}

label.required:after,
.form--check.required:after {
  content: "*";
  color: #dc3545 !important;
  margin-left: 2px;
}

.agree-policy.required:after {
  content: "";
}

@media (max-width: 1199px) {
  .add-new-wrapper .add-btn-wrapper {
    width: 100% !important;
    text-align: end;
  }
}

.cookies-card__btn .policy:hover {
  color: rgb(var(--main));
}

.form-check-wrapper .form--check:last-child {
  margin-bottom: 0;
}

.form-check-wrapper .form--check:last-child .form-check-label {
  margin-bottom: 0;
}

.form-check {
  margin-bottom: 0;
}

.border-bottom {
  border-bottom: 1px solid #dee2e62b !important;
}

@media (max-width: 450px) {
  .card-wrapper > .jp-card-container {
    transform-origin: center !important;
  }

  .jp-card-container {
    width: auto;
  }
}

@media (max-width: 400px) {
  .card-wrapper > .jp-card-container {
    margin-left: -10px;
  }
}

@media (max-width: 350px) {
  .card-wrapper > .jp-card-container {
    margin-left: -18px;
  }
}
